
import { ref, defineComponent } from 'vue'
import { BookmarkAdd as AddIcon } from '@vicons/carbon'
import * as api from '../../utils/api'
import Menu from '../../components/menu/Hospital.vue'
import Hospital from '../../components/Hospital.vue'
import moment from 'moment-timezone'
import { useDialog } from 'naive-ui'
moment.locale('ja')
moment.tz.setDefault('Asia/Tokyo')

export default defineComponent({
	components: {
		Menu,
		Hospital,
		AddIcon,
	},
	data() {
		const refProp = {
			query: '',
			showHidden: false,
		}
		const modelRef = ref(refProp)
		const dialog = useDialog()
		const errorDialog = (e: any, onPositiveClick: Function) =>
			dialog.error({
				title: 'Error',
				content: e.message || '不明なエラー',
				closable: false,
				positiveText: 'OK',
				onPositiveClick: () => onPositiveClick(),
			})
		return {
			interview: [],
			errorDialog,
			model: modelRef,
			loading: true,
		}
	},
	mounted: function () {
		document.title = '社員インタビュー一覧 - Vets Index'
		this.init()
	},
	methods: {
		init: async function () {
			try {
				const data = await api.get(`/v1/hospital/interview`)
				this.interview = data
				this.loading = false
			} catch (e: any) {
				this.loading = false
				this.errorDialog(e, () => true)
				console.error(e)
			}
		},
		addNew: function () {
			this.$router.push('/hospital/interview_new')
		},
		getDate: function (unix: number) {
			return moment(new Date(unix * 1000)).format('YYYY年MM月DD日')
		},
		goInterviewEdit: function (id: string) {
			this.$router.push(`/hospital/interview_edit/${id}`)
		},
		lookCheck: function (id: string) {
            window.open(`/h/${id}`, '_blank')
        },
		label: function (label: string) {
			if (label == 'draft') return '下書き'
			if (label == 'waitReview') return '審査待ち'
			if (label == 'accepted') return '公開中'
		},
		labelColor: function (label: string) {
			if (label == 'draft') return 'gray'
			if (label == 'waitReview') return 'orange'
			if (label == 'accepted') return 'green'
		},
	},
})
